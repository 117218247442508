// IMPORT
@import url('https://hello.myfonts.net/count/3befdf');

// BASE
$font-family: 'Aventa', system-font, 'Inter', sans-serif;
// GRID
$grid-width: 1320px;
$grid-column-gap: 40px;

// COLORS
$color-primary-900: #0b0d58;
$color-primary-800: #131683;
$color-primary-600: #22259f;
$color-primary-500: rgba(34, 37, 159, 0.5);
$color-primary-100: rgba(43, 47, 238, 0.08);

$color-blue-600: #3eb9ff;
$color-blue-800: #1990d3;
$color-blue-900: #0167a0;
$color-blue-500: rgba(62, 185, 255, 0.5);
$color-blue-100: rgba(62, 185, 255, 0.08);

$color-pink-600: #ffc4dd;
$color-pink-800: #f292bb;
$color-pink-900: #d55b8f;
$color-pink-500: rgba(255, 196, 221, 0.5);
$color-pink-100: rgba(255, 196, 221, 0.12);

$color-red-600: #f75673;
$color-red-800: #dd3856;
$color-red-900: #a4122d;
$color-red-500: rgba(247, 86, 115, 0.5);
$color-red-100: rgba(247, 86, 115, 0.08);

$color-yellow-600: #ffb0a3;
$color-yellow-800: #ea7e6c;
$color-yellow-900: #d75640;
$color-yellow-500: rgba(255, 176, 163, 0.5);
$color-yellow-200: rgba(255, 176, 163, 0.2);
$color-yellow-100: rgba(255, 176, 163, 0.08);

$color-green-600: #25dab9;
$color-green-800: #0eb798;
$color-green-900: #018c73;
$color-green-500: rgba(37, 218, 185, 0.5);
$color-green-100: rgba(37, 218, 185, 0.08);

$color-neutral-600: #8e9ac5;
$color-neutral-800: #6774a3;
$color-neutral-900: #27366a;
$color-neutral-500: #bbc3e0;
$color-neutral-300: #edf0fa;
$color-neutral-100: #f3f5fe;

// TYPO
$font-size-display: 64px;
$font-size-h1: 48px;
$font-size-h2: 42px;
$font-size-h3: 36px;
$font-size-h4: 32px;
$font-size-h5: 22px;
$font-size-body: 16px;

// Responsive
@mixin r($w) {
  @media screen and (max-width: $w) {
    @content;
  }
}
@mixin d($w) {
  @media screen and (min-width: $w) {
    @content;
  }
}
