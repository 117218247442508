.container {
  max-width: $grid-width;
  width: 100%;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  padding: 0 $grid-column-gap / 2;
}
.col-1 {
  width: 8.33333333%;
}
.col-2 {
  width: 16.66666667%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33333333%;
}
.col-5 {
  width: 41.66666667%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33333333%;
}
.col-8 {
  width: 66.66666667%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33333333%;
}
.col-11 {
  width: 91.66666667%;
}
.col-12 {
  width: 100%;
}

.button {
  height: 56px;
  padding: 0 22px;
  display: inline-flex;
  align-items: center;
  border-radius: 28px;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  letter-spacing: 0.01em;

  &.hover-animated-button {
    white-space: nowrap;
    padding: 0;

    &:hover {
      span > span {
        animation: marquee-button 2s linear infinite;
      }
    }

    span {
      position: relative;
      display: block;
    }

    & > span {
      overflow: hidden;

      & > span {
        padding: 0 22px;

        @keyframes marquee-button {
          0% {
            transform: translate3d(-35%, 0, 0);
          }
          100% {
            transform: translate3d(-135%, 0, 0);
          }
        }

        &::before,
        &::after {
          content: attr(data-text);
          padding: 0 22px;
          position: absolute;
        }

        &::before {
          left: 200%;
        }

        &::after {
          left: 100%;
        }
      }
    }
  }

  &--primary {
    background-color: $color-primary-600;
    color: #fff;
    transition: box-shadow 0.3s, background-color 0.3s;
    box-shadow: 0px 24px 64px rgba(16, 36, 140, 0.16);
  }

  &--primary:hover  {
    background-color: $color-primary-800;
    box-shadow: 0px 24px 64px rgba(16, 36, 140, 0.24);
  }

  &--primary:active  {
    box-shadow: 0px 24px 64px rgba(16, 36, 140, 0.16), 0 0 0 4px #8d8fcd;
  }

  &--header {
    box-shadow: 0px 34px 64px rgba(242, 146, 187, 0.32);
  }
  &--header:hover {
    box-shadow: 0px 34px 64px rgba(242, 146, 187, 0.48);
  }

  &--secondary {
    background-color: $color-blue-100;
    color: $color-primary-600;
    transition: box-shadow 0.2s, background-color 0.2s;

    &:hover {
      background-color: $color-blue-600;
      color: #fff;
    }

    &:active  {
      box-shadow: 0 0 0 4px $color-blue-500;
    }
  }
}

.link {
  color: $color-blue-600;
  font-weight: 500;
  position: relative;
  display: inline-flex;
  overflow: hidden;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 22px;
    width: calc(100% - 22px);
    background-color: $color-blue-600;
    height: 1px;
    transition: width 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    background-color: $color-blue-600;
    height: 1px;
    transition: width 0.3s;
  }

  &:hover:before {
    width: 0;
  }

  &:hover:after {
    width: calc(100% - 22px);
    transition-delay: 0.3s;
  }

  &__icon {
    width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    margin-left: 4px;
  }
}

.label {
  height: 36px;
  background-color: rgba(#ffc4dd, 0.3);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  color: $color-pink-900;
  letter-spacing: 0.02em;
  padding: 0 16px;

  @include r(480px) {
    height: 32px;
    font-size: 14px;
  }
}

.label--yellow {
  background-color: $color-yellow-100;
  color: $color-yellow-800;
}

.label--green {
  background-color: $color-green-100;
  color: $color-green-800;
}

.label--blue {
  background-color: $color-blue-100;
  color: $color-blue-800;
}

.label--neutral {
  background-color: $color-neutral-100;
  color: $color-neutral-600;
}

.label--yellow-200 {
  background-color: $color-yellow-200;
  color: $color-yellow-800;
}

.u-items-center {
  align-items: center;
}

.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-nowrap {
  white-space: nowrap;
}

.u-card-shadow {
  box-shadow: 0px 94px 150px rgba(17, 48, 160, 0.06);
}

.u-relative {
  position: relative;
}
