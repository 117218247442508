@font-face {
  font-family: 'Aventa';
  font-style: normal;
  font-display: swap;
  font-weight: normal;
  src: url('../../assets//fonts/Aventa-Regular/font.woff2') format('woff2'), url('../../assets//fonts/Aventa-Regular/font.woff') format('woff');
}

@font-face {
  font-family: 'Aventa';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../../assets//fonts/Aventa-Bold/font.woff2') format('woff2'), url('../../assets//fonts/Aventa-Bold/font.woff') format('woff');
}

@font-face {
  font-family: 'Aventa';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('../../assets//fonts/Aventa-SemiBold/font.woff2') format('woff2'), url('../../assets//fonts/Aventa-SemiBold/font.woff') format('woff');
}

@font-face {
  font-family: 'Aventa';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../../assets//fonts/Aventa-Medium/font.woff2') format('woff2'), url('../../assets//fonts/Aventa-Medium/font.woff') format('woff');
}

@font-face {
  font-family: 'Aventa';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('../../assets//fonts/Aventa-ExtraBold/font.woff2') format('woff2'), url('../../assets//fonts/Aventa-ExtraBold/font.woff') format('woff');
}

@font-face {
  font-family: 'Aventa';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url('../../assets//fonts/Aventa-Black/font.woff2') format('woff2'), url('../../assets//fonts/Aventa-Black/font.woff') format('woff');
}
